import { Component, OnInit } from '@angular/core';
import { GeneralInfoService } from '@core/services/general-info.service';

@Component({
    templateUrl: './layout.component.pug',
    styleUrls: ['./layout.component.sass']
})

export class LayoutComponent implements OnInit {

    constructor(
        public generalInfoService: GeneralInfoService
    ) {}

    ngOnInit(): void {
        //AntiExit
        //this.generalInfoService.modalAntiExit();
    }
}