import { Component, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeneralInfoService } from '@core/services/general-info.service';
import { WpService } from '@core/services/wp.service';
import { ApiService } from '@core/services/api.service';
import { ConfirmedValidator } from './confirmed.validator';
import { environment } from '@env/environment';
import { TagCommanderService } from 'ngx-tag-commander';

@Component({
    selector: 'app-register-form',
    templateUrl: './register-form.component.pug',
    styleUrls: ['./register-form.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class RegisterFormComponent implements OnInit, OnChanges{

    modalLeadForm: FormGroup;
    loginForm: FormGroup;
    recoverPasswordForm: FormGroup;
    createPasswordForm: FormGroup

    ctaPush: boolean = false;
    lostPasswordMessage: boolean = false;

    passwordError: boolean = false;
    entryPassword: boolean = false;
    loginError: boolean = false;

    showPassword: boolean = false;
    showPasswordConfirm: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        public generalInfoService: GeneralInfoService,
        public wp: WpService,
        private apiService: ApiService,
        private tcService: TagCommanderService  
    ) {}

    /**
     * Function to set tc vars
     */
    setTcVarsLeadError() {
        this.tcService.setTcVars({
            env_work : this.generalInfoService.envWork ? this.generalInfoService.envWorkPro : this.generalInfoService.envWorkPre,
            env_channel: this.generalInfoService.getEnvChannel(),
            basket_id: this.generalInfoService.basketId,
            gclid: this.generalInfoService.gClid,
            order_b2: this.generalInfoService.orderB2,
            order_dimensioni_tetto: this.generalInfoService.surface + ' ' + this.wp.dataThemeExtra?.mq,
            order_id: '',
            order_email: this.modalLeadForm.value.email,
            order_firstname: this.modalLeadForm.value.name,
            order_lastname: this.modalLeadForm.value.surname,
            order_telephone: this.modalLeadForm.value.phone,
            order_forma_tetto: this.generalInfoService.surfaceType,            
            order_indirizzo: this.generalInfoService.formattedAddress,
            order_n_antenne: this.generalInfoService.numberAntennas.toString(),
            order_n_camini: this.generalInfoService.numberChimneys.toString(),
            order_n_finestre: this.generalInfoService.numberWindows.toString(),            
            order_n_pannelli_ACS: this.generalInfoService.numberWaterCollectors.toString(),
            order_spesa_luce: this.generalInfoService.consumption + ' ' + this.wp.dataThemeExtra?.euro,
            order_provincia: this.generalInfoService.province,
            tc_data_segmentID: this.generalInfoService.tcDataSegmentID,
            page_category: this.generalInfoService.pageCategory,
            page_author: this.generalInfoService.author,
        });
    }

    setTcVarsLeadResultsError() {
        this.tcService.setTcVars({
            env_work : this.generalInfoService.envWork ? this.generalInfoService.envWorkPro : this.generalInfoService.envWorkPre,
            env_channel: this.generalInfoService.getEnvChannel(),
            basket_id: this.generalInfoService.basketId,
            gclid: this.generalInfoService.gClid,
            order_anni_rate: this.generalInfoService.financingYears,
            order_b2: this.generalInfoService.orderB2,
            order_batterie: this.generalInfoService.battery ? this.generalInfoService.textYes : this.generalInfoService.textNo,
            order_capacita_batterie: this.generalInfoService.batteryCapacity + ' ' + this.wp.dataThemeExtra?.kwh,
            order_costo_impianto: this.generalInfoService.profitabilityStorage.outputs.installation.price_with_vat + ' ' + this.wp.dataThemeExtra?.euro,
            order_costi_pratica: this.generalInfoService.profitabilityStorage.outputs.installation.price_without_vat + ' ' + this.wp.dataThemeExtra?.euro,
            order_dimensioni_tetto: this.generalInfoService.surface + ' ' + this.wp.dataThemeExtra?.mq,
            order_id: '',
            order_email: this.modalLeadForm.value.email,
            order_firstname: this.modalLeadForm.value.name,
            order_lastname: this.modalLeadForm.value.surname,
            order_telephone: this.modalLeadForm.value.phone,
            order_forma_tetto: this.generalInfoService.surfaceType,            
            order_indirizzo: this.generalInfoService.formattedAddress,
            order_listino: this.generalInfoService.orderListino,
            order_n_antenne: this.generalInfoService.numberAntennas.toString(),
            order_n_camini: this.generalInfoService.numberChimneys.toString(),
            order_n_finestre: this.generalInfoService.numberWindows.toString(),            
            order_n_pannelli_ACS: this.generalInfoService.numberWaterCollectors.toString(),
            order_payment_methods: this.generalInfoService.finance ? this.wp.dataSimulationStep3?.finance?.title : this.wp.dataSimulationStep3?.payment?.title,
            order_provincia: this.generalInfoService.province,
            order_risparmio: this.generalInfoService.savingMonth + ' ' + this.wp.dataThemeExtra?.euro,      
            order_risparmio_FV_annuo: this.generalInfoService.profitabilityStorage.outputs.self_consumption.total_savings[1] + ' ' + this.wp.dataThemeExtra?.euro,
            order_risparmio_FV_25anni: this.generalInfoService.profitabilityStorage.outputs.self_consumption.total_saving + ' ' + this.wp.dataThemeExtra?.euro,
            order_potenza_impianto: (this.generalInfoService.profitabilityStorage.outputs.installed_power / 1000) + ' ' + this.wp.dataThemeExtra?.kw,
            order_produzione_annua: (this.generalInfoService.profitabilityStorage.outputs.self_consumption.production[1] / 1000) + ' ' + this.wp.dataThemeExtra?.kwh,
            order_spesa_luce: this.generalInfoService.consumption + ' ' + this.wp.dataThemeExtra?.euro,
            order_tasso_autosufficienza: this.generalInfoService.profitabilityWithoutBatteriesStorage.outputs.self_consumption.yearly_self_consumption_percentage + ' ' + this.wp.dataThemeExtra?.percentage,
            tc_data_segmentID: this.generalInfoService.tcDataSegmentID,
            page_category: this.generalInfoService.pageCategory,
            page_author: this.generalInfoService.author,
        });
    }

    getRegisterLead() {
        this.modalLeadForm = this.formBuilder.group({
            name: [null, [
                Validators.required,
                Validators.maxLength(50),
                Validators.pattern('^[0-9A-Za-zñÑáéíóúÁÉÍÓÚÇ ]+$')
            ]],
            surname: [null, [
                Validators.maxLength(50),
                Validators.pattern('^[0-9A-Za-zñÑáéíóúÁÉÍÓÚÇ ]+$')
            ]],
            phone: [null, [
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(12)
            ]],
            email: [null, [
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
            ]],
            privacy: [false, Validators.requiredTrue],
        });
    }

    getLoginForm() {
        this.loginForm = this.formBuilder.group({
            email: [null, [
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
            ]],
            psswd: ['', [
                Validators.required
            ]]
        });
    }

    getCreatePasswordForm() {
        this.createPasswordForm = this.formBuilder.group({
            password: ['', [
                Validators.required
            ]],
            passwordConfirm: ['', [
                Validators.required
            ]]
        }, {
            validator: ConfirmedValidator('password', 'passwordConfirm')
        });
    }

    getRecoverpasswordForm() {
        this.recoverPasswordForm = this.formBuilder.group({
            email: [null, [
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
            ]]
        });
    }

    ngOnInit(): void {
        this.getRegisterLead();
        this.getLoginForm();
        this.getCreatePasswordForm();        
        this.getRecoverpasswordForm();  
        this.generalInfoService.initializeRegisterData();
    }   
    
    ngOnChanges(changes: SimpleChanges): void {
        this.ctaPush = false;
    }

    deleteError() {
        this.lostPasswordMessage = false;
        this.loginError = false;
    }

    /**
     * Function Register Lead
     */
    registerLead(): void {        
        const clientAddress: any = {            
            city: this.generalInfoService.addressComponents.city,
            company_id: environment.companyId,
            country_code: environment.countryCode,
            province: this.generalInfoService.province,
            zipcode: this.generalInfoService.addressComponents.zip,
            name: this.modalLeadForm.value.name,
            surname: this.modalLeadForm.value.surname,
            email: this.modalLeadForm.value.email,
            phone: this.modalLeadForm.value.phone,
            notes: null,
            campaign_hash: this.generalInfoService.campaignHash ? this.generalInfoService.campaignHash : environment.campaignHash,
            electric_consumption: null,
            external_id: "",
            lead_extra_data: [
                {
                    lead_field_slug: "indirizzo-ip-lead",
                    value: this.generalInfoService.userIP
                }
            ],            
        }        

        this.registerDataLead(clientAddress);
    }

    registerDataLead(data) {
        this.ctaPush = true;
        this.generalInfoService.showErrorCode = false;
        this.settAllFields();

        if(this.modalLeadForm.valid) {
            this.apiService.saveLead(data).subscribe({
                next: (response: any) => {
                    this.generalInfoService.isLead = true;
                    this.generalInfoService.pushCTAHouseInformation = true;
                    
                    //Get data Response
                    this.generalInfoService.dataForm = {
                        id: response.id,            
                        name: response.name,
                        surname: response.surname,
                        email: response.email,
                        phone: response.phone
                    }

                    this.generalInfoService.goToSection(this.generalInfoService.sectionThanks);

                    //TagCommander
                    this.generalInfoService.modalRicontattoLead();
                },        
                error: (e) => {  
                    this.generalInfoService.showErrorCode = true;         
                    this.generalInfoService.errorCode = e.error.internal_error_code;
                    this.generalInfoService.isLead = false;
                    this.generalInfoService.pushCTAHouseInformation = false;

                    //TagCommander
                    this.setTcVarsLeadError();
                },        
                complete() {},
            });
        } else {
            //TagCommander
            this.generalInfoService.activeEventFormError('modalClientContact', this.modalLeadForm);
        }        
    }

    setRequiredName() {
        if(this.modalLeadForm.value.name === null || this.modalLeadForm.value.name.length === 0 || this.modalLeadForm.controls.name.errors?.pattern) {
            this.generalInfoService.nameError = true
        } else {
            this.generalInfoService.nameError = false;
        }   
    }

    setRequiredSurname() {
        if(this.modalLeadForm.value.surname === null || this.modalLeadForm.value.surname.length === 0 || this.modalLeadForm.controls.surname.errors?.pattern) {
            this.generalInfoService.surnameError = true
        } else {
            this.generalInfoService.surnameError = false;
        }
    }
    
    setRequiredPhone() {
        if(this.modalLeadForm.value.phone === null || this.modalLeadForm.value.phone.length === 0 || this.modalLeadForm.controls['phone'].invalid) {
            this.generalInfoService.phoneError = true
        } else {
            this.generalInfoService.phoneError = false;
        }
    }

    setRequiredEmailLead() {
        if(this.modalLeadForm.value.email === null || this.modalLeadForm.value.email.length === 0 || this.modalLeadForm.controls.email.errors?.pattern) {
            this.generalInfoService.emailError = true
        } else {
            this.generalInfoService.emailError = false;
        }
    }

    /**
    * Function to register client
    */
    registerClient(): void {
        this.generalInfoService.pushCTAResults = true;
        const { inputs, outputs } = this.generalInfoService.parseInputsAndOutputs(this.generalInfoService.profitabilityStorage);
        
        const clientAddress: any = {
            address: this.generalInfoService.getAddress(),
            city: this.generalInfoService.addressComponents.city,
            client_type_id: environment.clientTypeID,
            country_id: environment.countryId,
            latitude: this.generalInfoService.latitude,
            longitude: this.generalInfoService.longitude,
            province: this.generalInfoService.province,
            zipcode: this.generalInfoService.addressComponents.zip,
            name: this.modalLeadForm.value.name,
            surname: this.modalLeadForm.value.surname,
            email: this.modalLeadForm.value.email,
            phone: this.modalLeadForm.value.phone,
            notes: null,
            opportunity_type_id: environment.opportunityTypeId, 
            opportunity_status_action: this.generalInfoService.pushCTAResults ? this.wp.dataVariables?.statusactionlead : this.wp.dataVariables?.statusactionclient,
            campaign_hash: this.generalInfoService.campaignHash ? this.generalInfoService.campaignHash : environment.campaignHash,
            simulation_data: this.generalInfoService.profitabilityStorage.outputs ? {
                electric_consumption: this.generalInfoService.consumption,
                surface: this.generalInfoService.surface,
                day_period_usage: JSON.stringify(''),
                address: this.generalInfoService.getAddress(),
                zipcode: this.generalInfoService.addressComponents.zip,
                city: this.generalInfoService.addressComponents.city ? this.generalInfoService.addressComponents.city : '',
                province: this.generalInfoService.province,
                latitude: this.generalInfoService.latitude,
                longitude: this.generalInfoService.longitude,
                roof_points: JSON.stringify(this.generalInfoService.coords[0]),
                inputs: inputs ? JSON.stringify(inputs) : undefined,
                outputs: JSON.stringify(outputs),
            } : undefined,
        }        

        this.registerInstallation(clientAddress);
    }

    registerInstallation(data) {
        this.ctaPush = true;
        this.generalInfoService.showErrorCode = false;
        this.settAllFields();

        if(this.modalLeadForm.valid) {
            this.apiService.saveClient(data).subscribe({
                next: (response: any) => {
                    this.generalInfoService.preflight = JSON.parse(response.preflight_data).preflight;
                    this.generalInfoService.opportunityId = response.opportunity_id;

                    //Get data Response
                    this.generalInfoService.dataForm = {
                        id: response.opportunity_id,
                        client_id: response.id,                
                        name: response.name,
                        surname: response.surname,
                        email: response.email,
                        phone: response.phone
                    }

                    this.generalInfoService.goToSection(this.generalInfoService.sectionThanks);

                    //TagCommander
                    this.generalInfoService.modalRicontattoLead();
                },        
                error: (e) => { 
                    this.generalInfoService.scroll0();
                    this.generalInfoService.showErrorCode = true;         
                    this.generalInfoService.errorCode = e.error.internal_error_code;
                    this.generalInfoService.pushCTAResults = false;

                    //TagCommander
                    this.setTcVarsLeadResultsError();
                },        
                complete() {},
            });
        } else {
            //TagCommander
            this.generalInfoService.activeEventFormError('modalClientContact', this.modalLeadForm);
        }
    }

    settAllFields() {
        this.setRequiredName();
        this.setRequiredSurname();
        this.setRequiredPhone();
        this.setRequiredEmailLead();
    }

    /**
     * Function to login user
     */
    accessLogin(): void {
        this.ctaPush = true;
        if (this.loginForm.valid) {
            const data = {
                email: this.loginForm.value.email,
                password: this.loginForm.value.psswd
            };

            this.apiService.clientLogin(data).subscribe({
                next: (response: any) => {
                    const preflight = response.preflight;
                    const companyId = response.companies[0].id;
                    window.open(environment.portalClientUrl + '?preflight=' + preflight + '&companyId=' + companyId);
                },        
                error: (e) => {
                    this.loginError = true;
                },        
                complete() {},
            });
        }
    }

    setRequiredEmail() {
        this.generalInfoService.entryEmail = true;
        this.generalInfoService.emailError = this.loginForm.controls['email'].errors ? true : false;   
    }

    setRequiredPassword() {
        this.entryPassword = true;
        this.passwordError = this.loginForm.controls['psswd'].errors ? true : false;   
    }

    /*
    Recover Password
    */
    recoverPassword(): void {
        this.ctaPush = true;
        if (this.recoverPasswordForm.valid) {
            const data = {
                email: this.recoverPasswordForm.value.email,
                action_location: 'simulator'
            };
            
            this.lostPasswordMessage = true;

            this.apiService.forgotPassword(data).subscribe({
                next: (response: any) => {
                    this.generalInfoService.successLostPassword = true;
                },        
                error: (e) => {
                },        
                complete() {},
            });
        }        
    }

    setRequiredEmailPassword() {
        this.generalInfoService.entryEmail = true;
        this.generalInfoService.emailError = this.recoverPasswordForm.controls['email'].errors ? true : false;   
    }
     
    /*
    Create Password
    */
    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    toggleShowPasswordConfirm() {
        this.showPasswordConfirm = !this.showPasswordConfirm;
    }

    changePassword(): void {
        this.ctaPush = true;
    }
}